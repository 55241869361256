// @ts-nocheck
import { shouldPolyfill as shouldPolyfillCanonical } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumber } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillPlural } from '@formatjs/intl-pluralrules/should-polyfill';

export async function initPolyfills() {
    // Current cases :
    // IE11
    // @ts-ignore
    if (!window.fetch) {
        await import('core-js/stable');
    }

    // Current cases :
    // IE11 - EDGE 14
    if (!Element.prototype.matches)
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function (s) {
                var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };

    // Current cases :
    // IE11 - EDGE 14
    if (!Element.prototype.closest)
        Element.prototype.closest = function (s: string) {
            var el = this;
            if (!document.documentElement.contains(el)) return null;
            do {
                if (el.matches(s)) return el;
                el = (el.parentElement || el.parentNode) as Element;
            } while (el !== null && el.nodeType === 1);
            return null;
        };

    if (shouldPolyfillCanonical()) {
        await import('@formatjs/intl-getcanonicallocales/polyfill');
    }
    if (shouldPolyfillLocale()) {
        await import('@formatjs/intl-locale/polyfill');
    }
    if (shouldPolyfillPlural()) {
        await import('@formatjs/intl-numberformat/polyfill');
        await import('@formatjs/intl-numberformat/locale-data/fr');
    }
    if (shouldPolyfillNumber()) {
        await import('@formatjs/intl-pluralrules/polyfill');
        await import('@formatjs/intl-pluralrules/locale-data/fr');
    }
}
